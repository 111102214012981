<template>
  <StoryblokBackgroundImage
    :image="blok.backgroundImage"
    background-color="var(--dp-blog-header-background)"
    class="flex flex-col blog-header-background md:flex-row md:gap-8 text-purple-100 px-[32px] md:px-[64px] py-[32px] relative bg-cover bg-center bg-no-repeat"
  >
    <img
      v-if="isVonageBrand && !blok.backgroundImage?.filename"
      :src="VonageLogo"
      class="hidden md:block absolute left-1/2 bottom-0 mix-blend-overlay transform -translate-x-1/2"
    />
    <img
      v-if="isVonageBrand && !blok.backgroundImage?.filename"
      :src="VonageLogoMobile"
      class="md:hidden absolute right-0 top-0 h-full mix-blend-overlay transform"
      alt=""
    />
    <div class="content max-w-[576px] blog-header-text">
      <RichText :text="blok.content" />
    </div>
    <div class="flex-grow" />
    <div class="flex flex-row gap-4 sm:gap-6" :class="[blok?.backgroundImage ? 'items-start' : 'items-center']">
      <SocialLink
        v-for="link in blok.socialLinks"
        :key="link._uid"
        type="button"
        :link="link"
        :color="link.color"
        :size="-4"
      />
    </div>
  </StoryblokBackgroundImage>
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue';
import VonageLogo from '@/assets/blog/vonage-logo.svg';
import VonageLogoMobile from '@/assets/blog/vonage-logo-mobile.svg';
import SocialLink from '@/components/utils/SocialLink.vue';
import { useBrandingStore } from '@/store/branding';
import RichText from '@/components/rich-text/RichText.vue';
import StoryblokBackgroundImage from '@/components/storyblok/StoryblokBackgroundImage.vue';
import type { LandingPageHeader } from '@developer-portal/storyblok';

defineProps({
  blok: { type: Object as PropType<LandingPageHeader>, required: true },
});

const brandingStore = useBrandingStore();
const isVonageBrand = computed(() => brandingStore.data?.vendorId === 'vonage');
</script>

<style scoped lang="scss">
.content {
  :deep(h2) {
    margin: 0;

    font: var(--vvd-typography-heading-5);

    @media (min-width: 768px) {
      font: var(--vvd-typography-heading-2);
    }
  }

  :deep(p) {
    font: var(--vvd-typography-base-light);
  }
}
.blog-header-background {
  background-color: var(--dp-blog-header-background);
}
.blog-header-text {
  color: var(--dp-blog-header-text);
}
</style>
